{
  "COMMON": {
    "LOGIN-TO": "Se connecter à "
  },
  "FORBIDDEN_VIEW": {
    "NEED_ACCESS": "Besoin de demander l’accès ?",
    "NEED_ACCESS_DESCRIPTION": "Décris ci-dessous pourquoi tu souhaiterais avoir accès à l’application.",
    "PLACEHOLDER_TEXTAREA": "Entre ta description (500 caractères max)",
    "SEND_DEMAND": " Demander l’accès ",
    "DEMAND_SENDED": "Ta demande d'habilitation a été envoyée.",
    "ALT_VERIF": "Representation visuelle de la vérification de tes droits"
  },
  "APP_NAME": {
    "LABEL": "Data<strong>Camp</strong>"
  },
  "ACTIONS": {
    "CLOSE": "Fermer",
    "RETRY": "Réessayer",
    "VALID": "Valider",
    "CLEAR": "Effacer",
    "CLEAR_SELECTION": "Effacer la sélection",
    "FILTER": "Filtrer",
    "SELECT_RESULT": "Tout sélectionner",
    "SEARCH": "Rechercher",
    "DOCUMENTATION": "Accéder à la Formation",
    "LOGOUT": "Se déconnecter"
  },
  "ERROR": {
    "LABEL": "Oups !!!"
  },
  "BACK_BTN": {
    "LABEL": "Revenir en arrière"
  },
  "SHARE_BY_COMMUNITY_BTN": {
    "LABEL": "Accéder au salon SmartClub"
  },
  "FULLSCREEN_BTN": {
    "LABEL": {
      "ON": "Passer en mode plein écran",
      "OFF": "Quitter le mode plein écran"
    }
  },
  "CAMPAIGN_DOWNLOAD_BTN": {
    "LABEL": "Exporter",
    "EXCEL": {
      "LABEL": "Au format Excel",
      "VERSION": {
        "OLD": "Versions antérieures à Excel 2007",
        "NEW": "Versions Excel 2007 et plus récentes"
      }
    }
  },
  "CAMPAIGN_PIVOT_BTN": {
    "ON": "Quitter le mode Pivot",
    "OFF": "Activer le mode Pivot"
  },
  "CAMPAIGN_COLUMN_SELECTOR_BTN": {
    "LABEL": "Afficher/masquer des colonnes"
  },
  "CAMPAIGN_FILTER_BTN": {
    "LABEL": "Filter"
  },
  "CAMPAIGN_SHARE_BY_URL_BTN": {
    "LABEL": "Copier-coller l'URL",
    "CONFIRM": "L'URL a été copiée dans le presse papier."
  },
  "LOADING": {
    "LABEL": "Chargement en  cours ..."
  },
  "CAMPAIGN_AVAILABLE_REPORTS": {
    "LABEL": "Générer un rapport"
  },
  "CAMPAIGN_NOT_FOUND": {
    "LABEL": "Campagne introuvable",
    "GO_BACK": "Retourner à la recherche"
  },
  "CAMPAIGN_TOP_INDICATORS": {
    "NUMBER_OF_DISTRIBUTED_VOUCHERS": "BR distribués",
    "MARKDOWN_EXCLUDING_TAX": "Démarque HT",
    "TURNOVER_BY_USED_VOUCHERS": "CA porté Global Mag",
    "RETURN_RATE": "Taux de retour",
    "RETURN_RATE_HELP": "Le taux de retour ne peut pas être calculé sur le format proximité",
    "LOADING": "Chargement des top indicateurs ..."
  },
  "CAMPAIGNS_TABLE": {
    "ID": "Identifiant",
    "TYPE": "Type",
    "DATE": "Date",
    "LABEL": "Nom",
    "STATUS": {
      "LABEL": "Status",
      "DONE": "Terminée",
      "IN_PROGRESS": "En cours",
      "TO_DO": "A venir"
    }
  },
  "REPORT_CAMPAIGN_BY_OFFER_TABLE": {
    "TOTAL": "Total",
    "DIMENSION": "Dimension",
    "OFFER": "Offre",
    "ID": "ID",
    "BROADCAST": {
      "FROM": "Début d'émission",
      "TO": "Fin d'émission"
    },
    "VALIDITY": {
      "FROM": "Début de validité",
      "TO": "Fin de validité"
    },
    "NUMBER_OF_EXPOSED_CUSTOMERS": "Client(s) unique à l'émission",
    "NUMBER_OF_USED_CUSTOMERS": "Client(s) unique à l'utilisation",
    "SITE_FORMAT": "Format",
    "NUMBER_OF_BROADCASTED_VOUCHERS": "Bon(s) distribué(s)",
    "NUMBER_OF_USED_VOUCHERS": "Bon(s) utilisé(s)",
    "RETURN_RATE": "% Taux de retour",
    "AVERAGE_NUMBER_OF_USED_CUSTOMERS": "Bon(s) moyen(s) utilisé(s) par client",
    "PERCENTAGE_USED_VOUCHERS_HOLDER": "% Bon(s) utilisé(s) avec cartes",
    "MARKDOWN_EXCLUDING_TAX": "Démarque HT",
    "OFFER_PERIMETER": {
      "LABEL": "Périmètre de l'offre",
      "TURNOVER_BY_USED_VOUCHERS": "CA offre porté à l'utilisation des bons",
      "TURNOVER": "CA tous clients du périmètre de l'offre",
      "CONTRIBUTION": "% Contribution au CA offre tous clients",
      "AVERAGE_CART": "Panier moyen offre à l'utilisation d'un bon"
    },
    "GLOBAL_PERIMETER": {
      "LABEL": "Périmètre global",
      "TURNOVER_BY_USED_VOUCHERS": "CA global porté à l'utilisation des bons",
      "TURNOVER": "CA global tous clients",
      "CONTRIBUTION": "% Contribution au CA global tous clients",
      "AVERAGE_CART": "Panier moyen global à l'utilisation d'un bon"
    }
  },
  "REPORT_CAMPAIGN_BY_OFFER_AND_STORE": {
    "NAME": "Debrief de la campagne par offre et par magasin",
    "CAMPAIGN": "{0}",
    "LABEL": "Aucune donnée | @:REPORT_CAMPAIGN_BY_OFFER_AND_STORE.NAME | @:REPORT_CAMPAIGN_BY_OFFER_AND_STORE.NAME",
    "DIMENSION1": "N°",
    "DIMENSION2": "Magasin",
    "DIMENSION3": "DO",
    "LOADING": "Chargement du rapport \"@:REPORT_CAMPAIGN_BY_OFFER_AND_STORE.NAME\" en cours ...",
    "ERROR": {
      "FIND_REPORTS": "Rapport \"@:REPORT_CAMPAIGN_BY_OFFER_AND_STORE.NAME\" pour la campagne {0} non trouvé"
    }
  },
  "REPORT_CAMPAIGN_BY_OFFER_AND_DAY": {
    "NAME": "Debrief de la campagne par offre et par jour",
    "CAMPAIGN": "{0}",
    "LABEL": "Aucune donnée | @:REPORT_CAMPAIGN_BY_OFFER_AND_DAY.NAME | @:REPORT_CAMPAIGN_BY_OFFER_AND_DAY.NAME",
    "DIMENSION1": "Jour",
    "LOADING": "Chargement du rapport \"@:REPORT_CAMPAIGN_BY_OFFER_AND_DAY.NAME\" en cours ...",
    "ERROR": {
      "FIND_REPORTS": "Rapport \"@:REPORT_CAMPAIGN_BY_OFFER_AND_DAY.NAME\" pour la campagne {0} non trouvé"
    }
  },
  "REPORT_CAMPAIGN_BY_OFFER_AND_GLOBAL": {
    "NAME": "Debrief de la campagne par offre",
    "CAMPAIGN": "{0}",
    "LABEL": "Aucune donnée | @:REPORT_CAMPAIGN_BY_OFFER_AND_GLOBAL.NAME | @:REPORT_CAMPAIGN_BY_OFFER_AND_GLOBAL.NAME",
    "LOADING": "Chargement du rapport \"@:REPORT_CAMPAIGN_BY_OFFER_AND_GLOBAL.NAME\" en cours ...",
    "ERROR": {
      "FIND_REPORTS": "Rapport \"@:REPORT_CAMPAIGN_BY_OFFER_AND_GLOBAL.NAME\" pour la campagne {0} non trouvé"
    }
  },
  "OFFERS_TIMELINE": {
    "LABEL": "Aucune offre associée | Offre associée | {0} offres associées",
    "LOADING": "Chargement des offres associées en cours ...",
    "ERROR": {
      "GET_OFFERS": "Un problème est survenu lors de la récupération des offres associées à la campagne {0}.<br><br>Merci de réessayer ultérieurement."
    },
    "NAME": "Nom",
    "BROADCAST": {
      "LABEL": "Emission"
    },
    "VALIDITY": {
      "LABEL": "Validité"
    },
    "NUMBER_OF_BROADCASTED_VOUCHERS": "BR distribués",
    "NUMBER_OF_USED_VOUCHERS": "Bons utilisés",
    "MARKDOWN_EXCLUDING_TAX": "Démarques HT",
    "TURNOVER_BY_USED_VOUCHERS": "CA offre porté à l'utilisation d'un bon",
    "RETURN_RATE": "Taux de retour"
  },
  "CAMPAIGN_TITLE": {
    "LABEL": "Campagne {0} / {1}"
  },
  "CAMPAIGN_SELECTOR": {
    "HELPER": "e.g. 70510, sushi, chocolat",
    "FOUND": "Aucune campagne trouvée | {count} campagne trouvée | {count} campagnes trouvées",
    "ID": "@:CAMPAIGN.ID",
    "LABEL": "@:CAMPAIGN.LABEL",
    "FROM": "@:CAMPAIGN.FROM",
    "TO": "@:CAMPAIGN.TO",
    "TYPE": "@:CAMPAIGN.TYPE",
    "GO_TO_DETAIL": "Voir le détail de cette campagne",
    "ACTIONS": {
      "SEARCH": "Rechercher"
    },
    "ERROR": {
      "GET_SEARCH": "Erreur lors de la recherche d'une campagne"
    }
  },
  "CAMPAIGN_SHOPPING_CART": {
    "SELECTION": "Aucune campagne sélectionnée | {count} campagne sélectionnée | {count} campagnes sélectionnées",
    "ACTIONS": {
      "SEE": "Voir"
    }
  },
  "CAMPAIGN": {
    "ID": "ID",
    "LABEL": "Nom",
    "FROM": "Début",
    "TO": "Fin",
    "TYPE": "Type"
  },
  "CAMPAIGNS": {
    "SEARCH_BY_ID": {
      "LABEL": "Recherche par ID campagne"
    },
    "SEARCH_BY_FREE": {
      "LABEL": "Recherche au format libre"
    }
  },
  "CAMPAIGNS_SEARCH_BY_ID": {
    "PLACEHOLDER": "Ajouter une campagne ...",
    "LIMIT": "0 campagne au maximum | 1 campagne maximum | {count} campagnes maximum",
    "ACTIONS": {
      "OPEN": "Ouvrir"
    },
    "ERROR": {
      "FIND_CAMPAIGN_BY_ID": "Campagne \"{0}\" introuvable."
    }
  },
  "APP_SELECTOR": {
    "LABEL": "Accéder aux applications de la DataSuite",
    "DATASTORE": {
      "LABEL": "Data<strong>Store</strong>",
      "DESCRIPTION": "Animation client"
    },
    "DATACAMP": {
      "LABEL": "Data<strong>Camp</strong>",
      "DESCRIPTION": "Débrief des campagnes"
    },
    "DATAPERF": {
      "LABEL": "Data<strong>Perf</strong>",
      "DESCRIPTION": "Performance budgétaire"
    }
  },
  "CAMPAIGN_LAYOUT": {
    "TOTAL": "Total"
  },
  "CAMPAIGN_PRIMARY_INFOS": {
    "TYPE": "@:CAMPAIGN.TYPE",
    "DATE": "Date",
    "STATUS": {
      "LABEL": "Status",
      "DONE": "Terminée",
      "IN_PROGRESS": "En cours",
      "TO_DO": "A venir"
    }
  },
  "CAMPAIGNS_TOP_INDICATORS": {
    "NUMBER_OF_DISTRIBUTED_VOUCHERS": "BR distribué | BR distribué | BR distribués",
    "MARKDOWN_EXCLUDING_TAX": "Démarque HT",
    "TURNOVER_BY_USED_VOUCHERS": "CA porté",
    "RETURN_RATE": "Taux de retour",
    "LOADING": "Chargement des top indicateurs ..."
  },
  "REPORT_CAMPAIGNS_TABLE": {
    "DIMENSION": "",
    "CAMPAIGN": "Campagne",
    "OFFER": "Offre",
    "STORE": "Magasin",
    "ID": "ID",
    "BROADCAST": {
      "FROM": "Début d'émission",
      "TO": "Fin d'émission"
    },
    "VALIDITY": {
      "FROM": "Début de validité",
      "TO": "Fin de validité"
    },
    "NUMBER_OF_EXPOSED_CUSTOMERS": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.NUMBER_OF_EXPOSED_CUSTOMERS",
    "NUMBER_OF_USED_CUSTOMERS": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.NUMBER_OF_USED_CUSTOMERS",
    "NUMBER_OF_BROADCASTED_VOUCHERS": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.NUMBER_OF_BROADCASTED_VOUCHERS",
    "NUMBER_OF_USED_VOUCHERS": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.NUMBER_OF_USED_VOUCHERS",
    "RETURN_RATE": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.RETURN_RATE",
    "AVERAGE_NUMBER_OF_USED_CUSTOMERS": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.AVERAGE_NUMBER_OF_USED_CUSTOMERS",
    "PERCENTAGE_USED_VOUCHERS_HOLDER": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.PERCENTAGE_USED_VOUCHERS_HOLDER",
    "MARKDOWN_EXCLUDING_TAX": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.MARKDOWN_EXCLUDING_TAX",
    "OFFER_PERIMETER": {
      "LABEL": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.OFFER_PERIMETER.LABEL",
      "TURNOVER_BY_USED_VOUCHERS": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.OFFER_PERIMETER.TURNOVER_BY_USED_VOUCHERS",
      "TURNOVER": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.OFFER_PERIMETER.TURNOVER",
      "CONTRIBUTION": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.OFFER_PERIMETER.CONTRIBUTION",
      "AVERAGE_CART": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.OFFER_PERIMETER.AVERAGE_CART"
    },
    "GLOBAL_PERIMETER": {
      "LABEL": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.GLOBAL_PERIMETER.LABEL",
      "TURNOVER_BY_USED_VOUCHERS": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.GLOBAL_PERIMETER.TURNOVER_BY_USED_VOUCHERS",
      "TURNOVER": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.GLOBAL_PERIMETER.TURNOVER",
      "CONTRIBUTION": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.GLOBAL_PERIMETER.CONTRIBUTION",
      "AVERAGE_CART": "@:REPORT_CAMPAIGN_BY_OFFER_TABLE.GLOBAL_PERIMETER.AVERAGE_CART"
    }
  },
  "CAMPAIGN_TOTAL": {
    "LOADING": "Chargement du rapport aggrégé en cours ..."
  },
  "NO_CAMPAIGN_OFFER": "Aucune offre associée à cette campagne n'a été trouvée."
}
